import React from 'react'
import { graphql } from 'gatsby'
import { ArticleLayout } from "../layouts/"

export const query = graphql`
    query ArticleTemplateQuery($id: String!) {
        model: sanityPost(id: { eq: $id }) {
            ...PostTemplate
        }
    }
`

const ArticleTemplate = (props) => {
    return (
        <ArticleLayout {...props} />
    )
}

export default ArticleTemplate
